// Libraries
import React, { FunctionComponent } from 'react';
// Components
// import { Tooltip } from '@grafana/ui';

interface Props {
  href?: string;
}

export const PaulWurthLogo: FunctionComponent<Props> = ({ href }) => {
  return <img className="sidemenu__logo_no_hover" src="public/img/Logo_Paul_Wurth.png" alt="Grafana" />;
};
